import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import WinPhone01 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App17.webp'
import WinPhone02 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App18.webp'
import WinPhone03 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App19.webp'
import WinPhone04 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App20.webp'
import WinPhone05 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App21.webp'
import WinPhone06 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App22.webp'
import WinPhone07 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App23.webp'
import WinPhone08 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App24.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The Image Preview settings affect the Multiview menu."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The video settings affect the live video of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The audio settings affect the live video of your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="If you only use your Windows Phone App to operate the camera, you can disable automatic synchronization on access."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The Live Tile settings affect the tile display of the app on the start page of your smartphone."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The help section gives you an overview of all functions of the app."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The help section gives you an overview of all functions of the app."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The help section gives you an overview of all functions of the app."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function SettingGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default SettingGallery