import React from 'react'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import FloatingGallery from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-settings-floating-gallery'
import SettingsTable from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-settings-table'

import NavButtons from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/NavButtons'

import SEOHelmet from '../../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Settings Menu :: InstarVision for Windows Phone',
  description: 'Adjust your apps global Settings for your app and all your cameras, create Backups and access the in-App Help Section with explanations for all function buttons.',
  image: '/images/Search/P_SearchThumb_InstarVision_WP.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_WP.webp',
  locationEN: '/en/Software/Windows/InstarVision/Windows_Phone/Settings/',
  locationDE: '/de/Software/Windows/InstarVision/Windows_Phone/Settings/',
  locationFR: '/fr/Software/Windows/InstarVision/Windows_Phone/Settings/'
}

function InstarVisionSettings(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
          locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Settings" />
          
      <NavButtons />
    
      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Phone Software</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <h3>Settings Menu</h3>
      <EuiSpacer size="l" />
      <FloatingGallery />
      <EuiSpacer size="xl" />
      <EuiTitle size="m">
        <h3>Adjust General Settings</h3>
      </EuiTitle>
      <EuiText>
        <p>The General Settings allow you to adjust certain video settings globally as well as to export or import your app configuration.</p>
      </EuiText>
      <EuiSpacer size="xl" />
      <SettingsTable />
    </React.Fragment>
  );
}

export default InstarVisionSettings